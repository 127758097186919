import React, { useEffect, useCallback } from 'react';
import LogService from '../services/logs.service';
import EventBus from '../common/EventBus';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { clearLoading, setLoading } from '../actions/loading';

const columns = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 50,
    view: (log) => {
      return <>{log.id}</>;
    },
  },
  {
    id: 'username',
    label: 'User Name',
    minWidth: 100,
    view: (value) => {
      return <>{value['username']}</>;
    },
  },
  {
    id: 'actionBy',
    label: 'Action By',
    minWidth: 170,
    view: (value) => {
      return <>{value['actionBy']}</>;
    },
  },
  {
    id: 'eventType',
    label: 'Event Type',
    minWidth: 170,
    view: (value) => {
      return <>{value['eventType']}</>;
    },
  },
  {
    id: 'IpAddress',
    label: 'IpAddress',
    minWidth: 170,
    view: (value) => {
      return <>{value['IpAddress']}</>;
    },
  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 170,
    view: (value) => {
      return <>{value['Status']}</>;
    },
  },
  {
    id: 'Event Time',
    label: 'Event Time',
    minWidth: 170,
    format: (value) => moment(value).format('MM/DD/YY hh:mmA'),
    view: (value) => {
      return <>{moment(value['createdAt']).format('MM/DD/YY hh:mmA')}</>;
    },
  },
];

const Logs = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [logs, setLogs] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState(['']);

  const [, setLogsMaster] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredLogs, setFilteredLogs] = React.useState([]); // Filtered and/or sorted users
  const [filterType, setFilterType] = React.useState('All'); // Default filter type Event type
  const [sortType, setSortType] = React.useState('All'); // Default sort type
  const [filterStatusType, setFilterStatusType] = React.useState('All');
  const [sortTime, setSortTime] = React.useState('descending');
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const getEventTypes = (logs) => {
    const eventTypes = logs.map((log) => log.eventType);
    return [...new Set(eventTypes)];
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    LogService.getLogs()
      .then(
        (response) => {
          setLogs([...response.data.logs[0]]);
          setEventTypes([...getEventTypes(response.data.logs[0])]);
          setLogsMaster([...response.data.logs[0]]); // If you use this for some other purpose
        },

        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      )
      .finally(() => {
        hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let filtered = [...logs];

    // Filtering based on roleId
    if (filterType !== 'All') {
      filtered = filtered.filter((log) => log.eventType.includes(filterType));
    }

    if (sortType !== 'All') {
      filtered = filtered.sort((a, b) => a.username.localeCompare(b.username));
    }

    if (filterStatusType !== 'All') {
      filtered = filtered.filter((log) =>
        log.Status.includes(filterStatusType)
      );
    }

    // Sort based on sortTime value
    if (sortTime === 'ascending') {
      // For ascending order
      filtered = filtered.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (sortTime === 'descending') {
      // For descending order (default case)
      filtered = filtered.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    setFilteredLogs(filtered);

    // Reset the pagination to the first page
    setPage(0);
  }, [logs, filterType, sortType, filterStatusType, sortTime]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);
  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  return shouldShow ? (
    <div
      style={{
        marginTop: '30px',
      }}
    >
      <Grid container spacing={2}>
        {/* Filter Dropdown */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <InputLabel>Filter By EventType</InputLabel>
              <Select
                label="Filter"
                onChange={(e) => setFilterType(e.target.value)}
              >
                {eventTypes?.map((eventType, index) => {
                  return (
                    <MenuItem value={eventType} key={index}>
                      {eventType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <InputLabel>Sort by User Name</InputLabel>
              <Select
                label="Sort by"
                onChange={(e) => setSortType(e.target.value)}
                defaultValue="All"
              >
                <MenuItem value="username">User Name</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <InputLabel>Filter By Status</InputLabel>
              <Select
                label="Filter"
                onChange={(e) => setFilterStatusType(e.target.value)}
              >
                <MenuItem value="success">Success</MenuItem>
                <MenuItem value="failure">Failure</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <InputLabel>Sort by Event Time</InputLabel>
              <Select
                label="Sort by"
                onChange={(e) => setSortTime(e.target.value)}
                defaultValue="descending"
              >
                <MenuItem value="descending">Latest</MenuItem>
                <MenuItem value="ascending">Oldest</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer style={{ marginTop: '20px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLogs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.view(row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredLogs.length} // <-- Make this change here
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  ) : (
    <div>
      <p>You don't have permission to view it. </p>
    </div>
  );
};

export default Logs;
