import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { login, loginWithGoogle } from '../actions/auth';

const Login = (props) => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const questionnaireValue = urlParams.get('questionnaire');
    const isQuestionnaire = questionnaireValue?.toString() === 'true';

    if (isQuestionnaire) {
      localStorage.setItem('isQuestionnaire', true);
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(login(data.username, data.password)).then(() => {
      props.history.push(
        localStorage.getItem('isQuestionnaire')?.toString() === 'true'
          ? '/clients'
          : '/personal_info'
      );
    });
  };

  const handleLoginResponse = (response) => {
    const { credential } = response;
    dispatch(loginWithGoogle(credential)).then(() => {
      if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
        props.history.push('/clients');
      } else {
        props.history.push('/personal_info');
      }

      let uniqueId = sessionStorage.getItem('uniqueId');
      if (!uniqueId) {
        uniqueId = Math.random().toString(36).substr(2, 9);
        sessionStorage.setItem('uniqueId', uniqueId);
      }
      const loginChannel = new BroadcastChannel('loginChannel');
      loginChannel.postMessage({ type: 'login', senderId: uniqueId });
      loginChannel.close();
    });
  };

  if (isLoggedIn) {
    return (
      <Redirect
        to={
          localStorage.getItem('isQuestionnaire')?.toString() === 'true'
            ? '/clients'
            : '/personal_info'
        }
      />
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div
        className="col-md-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'flex-start',
        }}
      >
        <div
          className="card card-container"
          style={{
            marginTop: '25vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            width: '100%',
            maxWidth: '400px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <img
            src="app_logo.png"
            alt="profile-img"
            className="profile-img-card"
            style={{ marginBottom: '20px' }}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="form-group"
              style={{ textAlign: 'center', width: '100%', padding: '0 50px' }}
            >
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Sign in as Levi"
                onSuccess={handleLoginResponse}
                onFailure={() => {
                  console.log('Login Failed');
                }}
                cookiePolicy={'single_host_origin'}
                style={{
                  width: '100%',
                  padding: '12px 20px',
                  fontSize: '16px',
                }}
              />
            </div>

            {message && (
              <div className="form-group">
                <div
                  className={
                    message ===
                    'You have been logged out due to inactivity. Please click on the login button to login again!'
                      ? 'alert alert-info'
                      : 'alert alert-danger'
                  }
                  role="alert"
                  style={{ width: '100%' }}
                >
                  {message}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
