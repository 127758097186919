import React, { useState, memo } from 'react';
import * as MUIv5 from '@mui/material';
import CareCoordinatorGeneralTable from './CareCoordinatorGeneralTable';
import CareCoordinatorPracticeInfoTable from './CareCoordinatorPracticeInfoTable';

const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>{' '}
    {/* Larger font size for title */}
    <div className="tabDescription">{description}</div>{' '}
    {/* Smaller font size for description */}
  </div>
);

const CareCoordinators = () => {
  const [showActiveCareCoordinators, setShowActiveCareCoordinators] =
    useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');

    setActiveTab(newValue);

    // Assuming the sidebar is only meant to be visible on the first tab (index 0)
    if (newValue !== 0) {
      // Hide the sidebar and remove the class when switching away from the first tab
    }
  };

  return (
    <div>
      <div
        className="tabs-container"
        style={{ position: 'sticky', top: 60, zIndex: 1000 }}
      >
        <MUIv5.Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="client tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          style={{
            paddingTop: '10px',
            background: '#F5F5F5',
          }}
        >
          <MUIv5.Tab
            label={
              <TabLabelWithDescription
                title="Personal Info"
                description="This includes Name, Email, DOB, etc"
              />
            }
            className={activeTab === 0 ? 'customTabSelected' : ''}
            classes={{ root: 'customTabRoot' }}
          />

          <MUIv5.Tab
            label={
              <TabLabelWithDescription
                title="Care Coordinator Practice Info "
                description="HIPAA Workforce, Schedule, etc"
              />
            }
            className={activeTab === 1 ? 'customTabSelected' : ''}
            classes={{ root: 'customTabRoot' }}
          />
        </MUIv5.Tabs>
      </div>

      <MUIv5.TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px',
          }}
        >
          <MUIv5.FormControlLabel
            control={
              <MUIv5.Switch
                checked={showActiveCareCoordinators}
                onChange={() => {
                  setShowActiveCareCoordinators(!showActiveCareCoordinators);
                  console.log(
                    'Switch toggled, showActiveCareCoordinators:',
                    !showActiveCareCoordinators
                  );
                }}
                name="showActiveCareCoordinators"
              />
            }
            label={
              showActiveCareCoordinators
                ? 'Active Care Coordinators'
                : 'Inactive Care Coordinators'
            }
          />
        </div>
      </MUIv5.TableContainer>

      {activeTab === 0 && (
        <CareCoordinatorGeneralTable
          showActiveCareCoordinators={showActiveCareCoordinators}
        />
      )}
      {activeTab === 1 && (
        <CareCoordinatorPracticeInfoTable
          showActiveCareCoordinators={showActiveCareCoordinators}
        />
      )}
    </div>
  );
};
export default memo(CareCoordinators);
