/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; // Use @mui/x-date-pickers for date pickers
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { saveFormData } from '../../actions/scheduling';
import { isValidEmail, extractEmailAddress } from '../../common/utility';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { FormControl } from '@mui/material';
import '../../styles/SchedulingStep01.css';

const SchedulingStep01 = ({
  handlePopulationChange,
  formValues: parentFormValues,
  setFormValues: setParentFormValues,
  foo,
  ...props
}) => {
  const noLabelRef = useRef();
  const yesLabelRef = useRef();

  const theme = createTheme();
  const state = useSelector((state) => state.scheduling);
  const dispatch = useDispatch();
  const [localFormValues, setLocalFormValues] = useState({
    ageLow: 18,
    ageHigh: 64,
  });

  const [showModal, setShowModal] = React.useState(false);
  const [selectedDate, handleDateChange] = React.useState(
    props?.step01FormValues?.date ||
      props?.selectedDockHealthPatient?.date_of_birth ||
      null
  );

  const [phoneAttr, setPhoneAttr] = React.useState(
    props?.step01FormValues?.phone ||
      props?.parsedDockHealthPatient?.phone ||
      ''
  );
  const formatPhone = (digits) => {
    if (digits) {
      return `(${digits?.slice(0, 3)}) ${digits?.slice(3, 6)}-${digits?.slice(
        6
      )}`;
    }
    return digits;
  };
  const [isUnderage, setIsUnderage] = useState(false); // added state variable
  const [firstNameFilled, setFirstNameFilled] = React.useState();
  const [lastNameFilled, setLastNameFilled] = React.useState();

  const isRestrictedEmailDomain = (email) => {
    const domain = email.split('@')[1]; // Get the domain part of the email
    return domain === 'icloud.com' || domain === 'me.com';
  };

  useEffect(() => {
    if (!isUnderage) {
      props.setUnderEighteenForm(true);
    } else {
      props.setUnderEighteenForm(false);
    }
  }, [isUnderage]);

  useEffect(() => {
    if (
      props.step01FormValues?.first_name ||
      props?.selectedDockHealthPatient?.first_name
    ) {
      setFirstNameFilled(true);
    } else {
      setFirstNameFilled(false);
    }
  }, [
    props?.step01FormValues?.first_name,
    props?.selectedDockHealthPatient?.first_name,
  ]);

  useEffect(() => {
    if (
      props?.step01FormValues?.last_name ||
      props?.selectedDockHealthPatient?.last_name
    ) {
      setLastNameFilled(true);
    } else {
      setLastNameFilled(false);
    }
  }, [
    props.step01FormValues?.last_name,
    props?.selectedDockHealthPatient?.last_name,
  ]);

  React.useEffect(() => {
    const newDate =
      props?.step01FormValues?.date ||
      props?.selectedDockHealthPatient?.date_of_birth ||
      null;
    if (newDate !== selectedDate) {
      handleDateChange(newDate);

      // Update isUnderage state variable
      const age = calculateAge(newDate);
      setIsUnderage(age < 18);
    }
  }, [
    props.step01FormValues?.date,
    props.selectedDockHealthPatient?.date_of_birth,
    selectedDate,
  ]);

  const isNotBlankOrNull = (text) => {
    if (!text) {
      return false;
    }

    if (text.length === 0) {
      return false;
    }
    return true;
  };

  const handleChangeDate = (date) => {
    // Ensure the date string is a valid date and has a valid year
    if (date instanceof Date && !isNaN(date) && date.getFullYear() > 1000) {
      handleDateChange(date);
      const event = {
        target: {
          name: 'date',
          value: date,
        },
      };
      handleChange(event);
      // update isUnderage state variable
      const age = calculateAge(date);
      setIsUnderage(age < 18);
    } else {
      // Handle invalid or incomplete date case if needed
      setIsUnderage(false);
    }
  };

  const handlePhoneChange = (event) => {
    let phone = event.target.value;

    let digits = phone.replace(/\D/g, '');
    let valid = true;
    if (!(digits.length === 10 || phone === 'na' || phone === ' ')) {
      // error: 'Any phone number format, but must be 10 digits long; If not applicable enter "na" or " " (space)'
      valid = false;
    } else {
      if (phone !== 'na' && phone !== ' ') {
        phone = formatPhone(digits);
      } else {
        valid = false;
      }
    }
    setPhoneAttr(phone);

    const eventNew = {
      target: {
        name: 'phone',
        value: valid ? digits : '',
      },
    };
    handleChange(eventNew);
  };

  const handlePhonePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pasteContent = event.clipboardData.getData('text/plain');
    // Manually trigger the change handler with the pasted content
    handlePhoneChange({ target: { value: pasteContent, name: 'phone' } });
  };

  const calculateAge = (dob) => {
    if (dob) {
      const diff_ms = Date.now() - dob.getTime();
      const age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState(
    props.step01FormValues?.email || props.selectedDockHealthPatient?.email
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleLocalPopulationChange = (event, newValue) => {
    // update the local state
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      ageLow: newValue[0],
      ageHigh: newValue[1],
    }));

    // call parent's handlePopulationChange function to update the parent's state
    handlePopulationChange(event, newValue);
  };

  const handleChange = (event) => {
    try {
      const {
        target: { value, name },
      } = event;

      // Check for restricted email domains
      if (name === 'email') {
        if (isRestrictedEmailDomain(value)) {
          alert(
            'Emails from icloud.com and me.com are not accepted. Please use another email address.'
          );
          setEmail(''); // Reset the email field
          const step1dataClone = { ...props.step01FormValues, email: '' }; // Update step01FormValues to reset email
          props.setStep01FormValues(step1dataClone);
          return; // Prevent further state updates or form submissions with this email
        }
      }

      const step1dataClone = { ...props.step01FormValues };
      step1dataClone[name] = value;
      props.setStep01FormValues(step1dataClone);
      switch (name) {
        case 'first_name':
          state.firstName = event.target.value;
          dispatch(saveFormData(state));
          break;
        case 'last_name':
          state.lastName = event.target.value;
          dispatch(saveFormData(state));
          break;
        case 'date':
          state.date = event.target.value;
          dispatch(saveFormData(state));
          break;
        case 'email':
          state.email = event.target.value;
          setEmail(event.target.value);
          dispatch(saveFormData(state));
          break;
        case 'phone':
          state.phone = event.target.value;
          dispatch(saveFormData(state));
          break;

        default:
          return null;
      }
    } catch (error) {
      console.error('step1.handleChange.error', error);
    }
  };

  const handleEmailPaste = (event) => {
    event.preventDefault(); // Stop the default paste action
    const pasteContent = event.clipboardData.getData('text/plain');
    // Optionally, clean up or validate the pasted content here if necessary
    handleChange({ target: { name: 'email', value: pasteContent.trim() } });
  };

  const showCancellationButton = () =>
    isCancellationFirstAppt() &&
    isNotBlankOrNull(props.step01FormValues.first_name) &&
    isNotBlankOrNull(props.step01FormValues.last_name) &&
    isNotBlankOrNull(props.step01FormValues.email);
  const isCancellationFirstAppt = () => {
    return (
      props.entryTypeFormValues?.entryType === 'cancellation' &&
      props.entryTypeFormValues?.cancellationOptions === 'new'
    );
  };

  const handleUnderEighteenChange = (event) => {
    props.setUnderEighteenForm(true);
    noLabelRef.current.style.color = 'black';
    yesLabelRef.current.style.color = 'black';

    if (event.target.value === 'yes') {
      setShowModal(true);
      props.setUnderEighteenFormResponse('Yes');
    } else {
      props.setUnderEighteenFormResponse('No');
    }
  };

  const isDateInvalid =
    !isCancellationFirstAppt() &&
    (!props?.step01FormValues?.date ||
      props?.step01FormValues?.date.toString() === 'Invalid Date');

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid container spacing={3} style={{ marginTop: '-115px' }}>
          {props.sectionsToShow.includes('firstName') && (
            <Grid item xs={4}>
              <TextField
                required
                error={firstNameFilled ? false : true}
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                name="first_name"
                label="First Name"
                value={
                  props?.selectedDockHealthPatient?.first_name ||
                  props?.step01FormValues?.first_name ||
                  ''
                }
                onChange={handleChange}
              />
            </Grid>
          )}
          {props.sectionsToShow.includes('lastName') && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                required
                error={lastNameFilled ? false : true}
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                name="last_name"
                label="Last Name"
                onChange={handleChange}
                value={
                  props.step01FormValues?.last_name ||
                  props?.selectedDockHealthPatient?.last_name ||
                  ''
                }
              />
            </Grid>
          )}
          {props.sectionsToShow.includes('ageRange') && (
            <div className="responsive-slider-container">
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="responsive-slider">
                  <ThemeProvider theme={theme}>
                    <Slider
                      variant="outlined"
                      step={1}
                      getAriaLabel={() => 'Age range'}
                      value={[localFormValues.ageLow, localFormValues.ageHigh]}
                      onChange={handleLocalPopulationChange}
                      valueLabelDisplay="on"
                    />
                  </ThemeProvider>
                </div>
              </Grid>
            </div>
          )}

          {/*The below style props are added to show the border */}
          {props.sectionsToShow.includes('dob') && (
            <Grid item xs={4}>
              <FormControl
                fullWidth
                error={isDateInvalid}
                style={
                  isDateInvalid
                    ? {
                        border: '1px solid red',
                        borderRadius: '4px',
                      }
                    : {}
                }
              >
                <DatePicker
                  required={!isCancellationFirstAppt()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={isDateInvalid}
                      variant="outlined"
                      placeholder="MM/DD/YYYY"
                    />
                  )}
                  label="Date Of Birth"
                  format="MM/dd/yyyy"
                  value={
                    props?.step01FormValues?.date ||
                    props?.selectedDockHealthPatient?.date_of_birth ||
                    null
                  }
                  name="date_of_birth"
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleChangeDate(date)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              {selectedDate && (
                <Typography variant="subtitle2" color="textSecondary">
                  {`Age: ${calculateAge(selectedDate)}`}
                </Typography>
              )}
            </Grid>
          )}

          {/* added condition to show additional question if user is under 18 */}
          {isUnderage && props.sectionsToShow.includes('divSep') && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Are the parents of prospective client divorced or separated?
                <IconButton onClick={handleClick}>
                  <HelpOutlineIcon fontSize="default" />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography>
                    Click{' '}
                    <a
                      href="https://docs.google.com/document/d/1E-U_F2vANZUkD2t6alDeNESlRjQBw4JJ-rqw4GQkUmM/edit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{' '}
                    for summary and review of under 18 policies.
                  </Typography>
                </Popover>
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <label
                  htmlFor="no"
                  style={{ marginRight: '10px', color: 'red' }}
                  ref={noLabelRef}
                  id="labelno"
                >
                  No*
                </label>

                <div style={{ marginRight: '10px' }}>
                  <input
                    type="radio"
                    id="no"
                    name="parentsSeparated"
                    value="no"
                    onChange={(event) => handleUnderEighteenChange(event)}
                    required
                  />
                </div>
                <label
                  htmlFor="yes"
                  style={{ marginRight: '10px', color: 'red' }}
                  id="labelyes"
                  ref={yesLabelRef}
                >
                  Yes*
                </label>
                <div>
                  <input
                    type="radio"
                    id="yes"
                    name="parentsSeparated"
                    value="yes"
                    required
                    onChange={(event) => handleUnderEighteenChange(event)}
                  />
                </div>
              </div>
            </Grid>
          )}

          {props.sectionsToShow.includes('divSepModal') && (
            <Modal open={showModal} onClose={() => setShowModal(false)}>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '16px',
                  height: '6in',
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Under 18 Add-On
                </Typography>
                <iframe
                  title="jotform"
                  src="https://form.jotform.com/213553311705043"
                  style={{ width: '100%', height: '70vh', border: 'none' }}
                ></iframe>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: '#4CAF50' }}
                    onClick={() => setShowModal(false)}
                  >
                    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                      &#10003;
                    </span>{' '}
                    I have completed the Under-18 jotform
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>
                      X
                    </span>{' '}
                    Cancel - Just Close this Screen
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {props.sectionsToShow.includes('email') && (
            <Grid item xs={4}>
              <TextField
                required
                error={
                  !isValidEmail(
                    extractEmailAddress(props.step01FormValues?.email) ||
                      extractEmailAddress(
                        props?.selectedDockHealthPatient?.email
                      ) ||
                      extractEmailAddress(email)
                  )
                }
                fullWidth
                variant="outlined"
                name="email"
                label="Email"
                autoComplete="email"
                onChange={handleChange}
                onPaste={handleEmailPaste}
                value={
                  extractEmailAddress(props.step01FormValues?.email) ||
                  extractEmailAddress(
                    props?.selectedDockHealthPatient?.email
                  ) ||
                  extractEmailAddress(email)
                }
                InputLabelProps={{
                  shrink: !!(
                    extractEmailAddress(props.step01FormValues?.email) ||
                    extractEmailAddress(
                      props?.selectedDockHealthPatient?.email
                    ) ||
                    extractEmailAddress(email)
                  ),
                }}
              />
            </Grid>
          )}

          {props.sectionsToShow.includes('phone') && (
            <Grid item xs={4}>
              <TextField
                required={!isCancellationFirstAppt()}
                error={
                  !isCancellationFirstAppt() &&
                  (props.step01FormValues?.phone ? false : true)
                }
                type="tel"
                autoComplete="new-password"
                fullWidth
                variant="outlined"
                name="phone"
                label="Phone"
                onChange={handlePhoneChange}
                onPaste={handlePhonePaste}
                value={
                  phoneAttr ||
                  formatPhone(props?.step01FormValues?.phone) ||
                  formatPhone(props?.parsedDockHealthPatient?.phone) ||
                  ''
                }
              />
            </Grid>
          )}

          {props.sectionsToShow.includes('cancelButton') && (
            <Grid item xs={4}>
              {showCancellationButton() && (
                <Button
                  variant="contained"
                  onClick={async (e) => {
                    props.setOpenCancellation(true);
                  }}
                >
                  Ok
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(SchedulingStep01);
