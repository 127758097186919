import React, { memo, useEffect, useState } from 'react';
import * as MUIv5 from '@mui/material';
import { Col, Row } from 'reactstrap';

const MenuProps = { PaperProps: { className: 'customMenuPaper' } };

const resultOptions = ['Information Only Inquiry', 'Referred Out'];
const reasonOptions = [
  'Cost',
  'Fully-Licensed Only',
  'In-Office Only Request',
  'Insurance - Related',
  'Not Appropriate for VS',
  'Psychologist Only',
  'Speciality - Not Offering',
  'Time - No Availability',
  'Time - Declined Times Offered',
  'Other',
];

const InQuirySchedulingModal = ({
  openLogNonScheduling,
  handleCloseOpenLogNonScheduling,
  handleChangeResult,
  selectedResult,
  selectedCareCoordinator,
  setSelectedCareCoordinator,
  selectedReason,
  handleChangeReason,
  createLogNonScheduling,
  reasonValue,
  setReasonValue,
  selectedProvidersNonScheduling,
  handleChangeNonScheduling,
  activeProviders,
  setOpenLogNonScheduling,
  taskDropdownOptions,
  activeCareCoordinators,
}) => {
  // State variables
  const [dockHealthTasksReviewed, setDockHealthTasksReviewed] = useState(false);

  useEffect(() => {
    setDockHealthTasksReviewed(taskDropdownOptions.length === 0);
  }, [taskDropdownOptions]);

  return (
    <MUIv5.Modal
      open={openLogNonScheduling}
      onClose={handleCloseOpenLogNonScheduling}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ height: 'auto', maxHeight: '80vh', overflowY: 'auto' }}
    >
      <MUIv5.Box className="modalStyle">
        {!dockHealthTasksReviewed && taskDropdownOptions.length > 0 ? (
          <>
            <MUIv5.Typography variant="h6">
              Please review the Dock Health tasks below (List is Scrollable):
            </MUIv5.Typography>

            <MUIv5.List
              style={{
                width: '100%',
                maxHeight: '300px',
                overflowY: 'auto',
                marginTop: '16px',
                marginBottom: '16px',
                border: '1px solid rgba(0,0,0,0.12)',
                borderRadius: '4px',
              }}
            >
              {taskDropdownOptions.map((task) => (
                <MUIv5.ListItem button key={task.id}>
                  <MUIv5.ListItemText primary={task.description} />
                </MUIv5.ListItem>
              ))}
            </MUIv5.List>

            <MUIv5.FormControlLabel
              control={
                <MUIv5.Checkbox
                  checked={dockHealthTasksReviewed}
                  onChange={(e) => setDockHealthTasksReviewed(e.target.checked)}
                />
              }
              label="I have reviewed the list of Dock Health tasks and none of them are relevant to this non-scheduling"
            />

            <MUIv5.Typography variant="body1">
              If one of the tasks in the Dock Health was relevant to this
              non-scheduling log, you can{' '}
              <MUIv5.Link
                href="https://app.dock.health/#/core/tasks/82d4871f-5857-11ea-a040-026e672186c9"
                target="_blank"
                rel="noopener"
              >
                click here
              </MUIv5.Link>{' '}
              to proceed.
            </MUIv5.Typography>

            <MUIv5.Button
              variant="contained"
              onClick={() => setOpenLogNonScheduling(false)}
              sx={{ marginTop: 2 }}
            >
              CANCEL
            </MUIv5.Button>
          </>
        ) : (
          <>
            <MUIv5.Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              What were the result of the inquiry?
            </MUIv5.Typography>

            <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
              <MUIv5.InputLabel id="demo-multiple-checkbox-label">
                Care Coordinator
              </MUIv5.InputLabel>
              <MUIv5.Select
                labelId="demo-multiple-checkbox-label"
                name="care_coordinators"
                value={selectedCareCoordinator}
                onChange={(e) => setSelectedCareCoordinator(e.target.value)}
                input={<MUIv5.OutlinedInput label="Care Coordinators" />}
                MenuProps={MenuProps}
              >
                {activeCareCoordinators?.map((obj) => (
                  <MUIv5.MenuItem
                    key={obj.care_coordinator_name}
                    value={obj.care_coordinator_name}
                  >
                    <MUIv5.Checkbox
                      checked={
                        selectedCareCoordinator === obj.care_coordinator_name
                      }
                    />
                    <MUIv5.ListItemText primary={obj.care_coordinator_name} />
                  </MUIv5.MenuItem>
                ))}
              </MUIv5.Select>
            </MUIv5.FormControl>

            <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
              <MUIv5.InputLabel id="demo-multiple-checkbox-label">
                Result
              </MUIv5.InputLabel>
              <MUIv5.Select
                labelId="demo-multiple-checkbox-label"
                name="apptTypes"
                value={selectedResult}
                onChange={handleChangeResult}
                input={<MUIv5.OutlinedInput label="apptTypes" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {resultOptions.map((masterObj) => (
                  <MUIv5.MenuItem key={masterObj} value={masterObj}>
                    <MUIv5.Checkbox
                      checked={selectedResult.indexOf(masterObj) > -1}
                    />
                    <MUIv5.ListItemText primary={masterObj} />
                  </MUIv5.MenuItem>
                ))}
              </MUIv5.Select>
            </MUIv5.FormControl>

            {selectedResult.length > 0 &&
              selectedResult[0] !== 'Information Only Inquiry' && (
                <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
                  <MUIv5.InputLabel id="demo-multiple-checkbox-label">
                    Reason
                  </MUIv5.InputLabel>
                  <MUIv5.Select
                    labelId="demo-multiple-checkbox-label"
                    name="apptTypes"
                    value={selectedReason}
                    onChange={handleChangeReason}
                    input={<MUIv5.OutlinedInput label="apptTypes" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {reasonOptions.map((masterObj) => (
                      <MUIv5.MenuItem key={masterObj} value={masterObj}>
                        <MUIv5.Checkbox
                          checked={selectedReason.indexOf(masterObj) > -1}
                        />
                        <MUIv5.ListItemText primary={masterObj} />
                      </MUIv5.MenuItem>
                    ))}
                  </MUIv5.Select>
                </MUIv5.FormControl>
              )}

            {selectedReason.length > 0 && selectedReason[0] === 'Other' && (
              <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
                <MUIv5.TextField
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  id="static_doxy_link"
                  label="Please specify..."
                  defaultValue={reasonValue}
                  onChange={(e) => {
                    setReasonValue(e.target.value);
                  }}
                />
              </MUIv5.FormControl>
            )}

            {
              <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
                <MUIv5.InputLabel id="demo-multiple-checkbox-label">
                  What provider(s) did the client ask for?
                </MUIv5.InputLabel>
                <MUIv5.Select
                  multiple
                  labelId="demo-multiple-checkbox-label"
                  name="providers"
                  value={selectedProvidersNonScheduling}
                  onChange={handleChangeNonScheduling}
                  input={<MUIv5.OutlinedInput label="Providers" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {activeProviders.map((obj) => (
                    <MUIv5.MenuItem
                      key={obj.provider_name}
                      value={obj.provider_name}
                    >
                      <MUIv5.Checkbox
                        checked={
                          selectedProvidersNonScheduling.findIndex(
                            (masterObj) => {
                              return masterObj === obj.provider_name;
                            }
                          ) > -1
                        }
                      />
                      <MUIv5.ListItemText primary={obj.provider_name} />
                    </MUIv5.MenuItem>
                  ))}
                </MUIv5.Select>
              </MUIv5.FormControl>
            }

            <Row xs="12" sx={{ margin: 2 }}>
              <Col xs="6"></Col>
              <Col xs="6">
                <Row>
                  <Col>
                    <MUIv5.Button
                      variant="contained"
                      onClick={async (e) => {
                        setOpenLogNonScheduling(false);
                      }}
                    >
                      CANCEL
                    </MUIv5.Button>
                  </Col>
                  <Col>
                    <MUIv5.Button
                      variant="contained"
                      disabled={
                        !selectedProvidersNonScheduling.length || // Ensures selectedProviders NonScheduling has a value
                        !selectedResult.length || // Ensures a selection has been made in selectedResult
                        (selectedResult[0] !== 'Information Only Inquiry' &&
                          !selectedReason.length) || // For other selections, ensures selectedReason is not empty
                        (selectedReason[0] === 'Other' && !reasonValue) // For 'Other' reason, ensures reasonValue is not empty
                      }
                      onClick={async (e) => {
                        createLogNonScheduling();
                      }}
                    >
                      SUBMIT
                    </MUIv5.Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </MUIv5.Box>
    </MUIv5.Modal>
  );
};

export default memo(InQuirySchedulingModal);
