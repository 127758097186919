import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination } from '@mui/material';
import UserService from '../../services/user.service';
import { useSnackbar } from 'notistack';
import providerService from '../../services/provider.service';

function compareProviderFullName(a, b) {
  // Safely split the provider_name, default to empty string if provider_name is missing
  const lastNameA = a.provider_name ? a.provider_name.split(' ')[1] || '' : '';
  const lastNameB = b.provider_name ? b.provider_name.split(' ')[1] || '' : '';

  return lastNameA.localeCompare(lastNameB);
}

export default function ProviderProfessionalInfoTable({
  showActiveProviders,
  setShowActiveProviders,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState([]);

  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const status = showActiveProviders ? 'active' : 'inactive';

    providerService
      .getProviders(status)
      .then((response) => {
        // Filter out any providers with a null provider_name right after fetching
        const fetchedProviders =
          response?.data?.providers.filter(
            (provider) => provider.provider_name != null
          ) ?? [];

        return UserService.getAllUsers().then((usersResponse) => {
          const users = usersResponse?.data?.users ?? [];

          // Enrich providers with user details including imageUrl
          const providersWithImages = fetchedProviders.map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            };
          });

          // Now proceed with fetching additional details for each provider and updating state
          // Convert each provider detail fetching into a promise
          const fetchDetailsPromises = providersWithImages.map((provider) =>
            getProviderDetail(provider.id, provider)
              .then((detail) => ({ ...provider, ...detail })) // Merge provider with its details
              .catch((error) => {
                console.error(
                  'Failed to fetch details for provider:',
                  provider.id,
                  error
                );
                return provider; // Return provider as is in case of error
              })
          );

          Promise.all(fetchDetailsPromises).then((completedProviders) => {
            // Further filter out any enriched providers if needed
            const validProviders = completedProviders.filter(
              (provider) => provider.provider_name != null
            );
            setLocations(validProviders);
            setProviders(validProviders); // Update locations with all fetched and valid details
          });
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          timeout: 3000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveProviders, enqueueSnackbar]); // Include alert if used within the effect

  // Adjust getProviderDetail to return details instead of setting state
  const getProviderDetail = async (id, provider) => {
    try {
      const response = await providerService.getTherapyConfig(id);
      const therapyLocations = response?.data?.therapy?.locations || '';
      const testingLocations = response?.data?.testing?.locations || '';
      const allLocations = [...testingLocations, ...therapyLocations].filter(
        Boolean
      );
      const uniqueLocations = [...new Set(allLocations)].join(',');

      return {
        location: uniqueLocations || ' ',
        last_attestation: response?.data?.last_attestation,
        last_roster_submission: response?.data?.last_roster_submission,
      };
    } catch (error) {
      console.error('Error fetching provider details:', error);
      // Return some default/fallback detail structure in case of error
      return {
        location: ' ',
        last_attestation: null,
        last_roster_submission: null,
      };
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page to 0 when changing the rows per page
  };

  // ...

  const displayedProviders = useMemo(() => {
    // Sort providers just before rendering
    return locations
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(compareProviderFullName);
  }, [locations, page, rowsPerPage]);

  const tableHeaderCellStyle = {
    borderBottom: '0.5px solid black',
    fontWeight: '900',
    position: 'sticky',
    top: '0px',
    zIndex: 100,
    backgroundColor: '#F5F5F5',
  };

  const tableCellStyle = { borderBottom: '0.1px solid black' };

  return (
    <div>
      <TableContainer
        component={Paper}
        id="child"
        style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
      >
        <Table
          sx={{ minWidth: 650 }}
          size="medium"
          aria-label="Providers_table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderCellStyle}>Full Name</TableCell>
              <TableCell style={tableHeaderCellStyle}>Supervisor</TableCell>
              <TableCell style={tableHeaderCellStyle}>
                Graduating School
                <br />
                (Graduating Date)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {displayedProviders?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell style={tableCellStyle}>
                  {row?.provider_name}
                </TableCell>

                <TableCell style={tableCellStyle}>
                  {row?.supervisor_name}
                </TableCell>

                <TableCell style={tableCellStyle}>
                  {row.diplomas && row.diplomas.length > 0
                    ? row.diplomas.map((diploma, diplomaIndex) => (
                        <React.Fragment key={diplomaIndex}>
                          {diploma.graduating_school}
                          <br />(
                          {new Date(diploma.graduating_date).toLocaleDateString(
                            'en-US',
                            {
                              year: '2-digit',
                              month: 'numeric',
                              day: 'numeric',
                            }
                          )}
                          )
                          {diplomaIndex < row.diplomas.length - 1 ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </React.Fragment>
                      ))
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={providers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />
    </div>
  );
}
