import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, memo } from 'react';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { isSelectedUserSupervisee } from '../../common/utility';
import { useSnackbar } from 'notistack';
import '../../styles/ProviderConfig.css';
import providerService from '../../services/provider.service';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ProviderPracticeInfo = (props) => {
  const [inHIPAA, setInHIPAA] = React.useState('');
  const [hrsPerWeek, setHrsPerWeek] = React.useState('');
  const [schedule, setSchedule] = React.useState('');
  const [llcName, setLlcName] = React.useState('');
  const [llcAddress, setLlcAddress] = React.useState('');
  const [sdatNumber, setSdatNumber] = React.useState('');
  const [taxID, setTaxID] = React.useState('');

  const isDoctorAdminSupervisor = (user) => {
    return (
      user &&
      user.roles &&
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInHIPAA(Boolean(props.selectedUserDetail?.hipaa) ?? '');
    setSchedule(props.selectedUserDetail?.schedule ?? '');
    setLlcName(props.selectedUserDetail?.llc_name ?? '');
    setLlcAddress(props.selectedUserDetail?.llc_address ?? '');
    setSdatNumber(props.selectedUserDetail?.sdat_num ?? '');
    setTaxID(props.selectedUserDetail?.tax_id ?? '');
    setHrsPerWeek(props.selectedUserDetail?.hrs_p_week ?? '');
  }, [props.selectedUserDetail]);

  const handleOnSubmit = async (event) => {
    try {
      event.preventDefault();
      if (props?.selectedUser?.id) {
        if (
          props.selectedUserDetail?.provider_email &&
          hrsPerWeek &&
          schedule
        ) {
          const response = await providerService.updateProviderDetails(
            props.selectedUser.id,
            {
              email: props.selectedUserDetail?.provider_email,
              hipaa: inHIPAA,
              hrs_p_week: hrsPerWeek,
              schedule: schedule,
              llc_name: llcName,
              llc_address: llcAddress,
              sdat_num: sdatNumber,
              tax_id: taxID,
              supervisorEmail: props.selectedUserDetail?.supervisor_email,
              supervisorName: props.selectedUserDetail?.supervisor_name,
              degreesAndLicense: props.selectedUserDetail?.degrees_and_license,
              licensedInOtherStates:
                props.selectedUserDetail?.licensed_in_other_states,
              inTherapy: props.selectedUserDetail?.in_therapy,
              inTesting: props.selectedUserDetail?.in_testing,
              aetna_effective_date:
                props.selectedUserDetail?.aetna_effective_date,
              bcbs_effective_date:
                props.selectedUserDetail?.bcbs_effective_date,
              cigna_effective_date:
                props.selectedUserDetail?.cigna_effective_date,
              hopkins_ehp_effective_date:
                props.selectedUserDetail?.hopkins_ehp_effective_date,
              hopkins_usfhp_effective_date:
                props.selectedUserDetail?.hopkins_usfhp_effective_date,
              medicaid_effective_date:
                props.selectedUserDetail?.medicaid_effective_date,
              tricare_effective_date:
                props.selectedUserDetail?.tricare_effective_date,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Provider details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          }

          enqueueSnackbar('Updated Provider ', {
            variant: 'success',
            timeout: 3000,
          });
          props.updateProviderDetail();
        } else {
          enqueueSnackbar('Please select required fields.', {
            variant: 'error',
            timeout: 3000,
          });
        }
      } else {
        enqueueSnackbar('Please select provider first ', {
          variant: 'error',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    }
  };
  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className="providerNotice1">
          All sections of the provider configuration is read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className="providerNotice1">
            The "Personal Info" section of the provider profile is read-only.
            Please reach out to Levi or Victoria if there is any information
            that you would like to update.
          </Typography>
        )}
      <div className="mainContainer">
        <Grid container spacing={3} className="GridItemTextField">
          <Grid item xs={3}>
            <FormControl
              className="fullWidthTextField borderedDropdown"
              sx={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                HIPAA Workforce
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inHIPAA}
                onChange={(event) => {
                  setInHIPAA(event.target.value);
                }}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="hrsPerWeek"
                label="Hours Per Week"
                value={hrsPerWeek}
                disabled={props.isProvider}
                onChange={(e) => {
                  setHrsPerWeek(e.target.value);
                }}
              />
            </Grid>
          }

          {
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="schedule"
                label="Schedule"
                value={schedule}
                disabled={props.isProvider}
                onChange={(e) => {
                  setSchedule(e.target.value);
                }}
              />
            </Grid>
          }

          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="llcName"
                label="LLC Name"
                value={llcName}
                disabled={props.isProvider}
                onChange={(e) => {
                  setLlcName(e.target.value);
                }}
              />
            </Grid>
          )}

          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="llcAddress"
                label="LLC Address"
                value={llcAddress}
                disabled={props.isProvider}
                onChange={(e) => {
                  setLlcAddress(e.target.value);
                }}
              />
            </Grid>
          )}

          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="sdatNumber"
                label="SDAT #"
                value={sdatNumber}
                disabled={props.isProvider}
                onChange={(e) => {
                  setSdatNumber(e.target.value);
                }}
              />
            </Grid>
          )}

          {!isSelectedUserSupervisee(props.selectedUserDetail) && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="taxID"
                label="Tax ID"
                value={taxID}
                disabled={props.isProvider}
                onChange={(e) => {
                  setTaxID(e.target.value);
                }}
              />
            </Grid>
          )}

          <Grid container spacing={3} className="GridItemTextField">
            <Grid item xs={3}>
              <button
                xs={3}
                className="btn btn-primary btn-block buttonWithMargin"
                type="button"
                onClick={handleOnSubmit}
                disabled={props.isProvider}
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderPracticeInfo);
