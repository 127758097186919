import React, { useState, useEffect, memo } from 'react';
import FormControl from '@mui/material/FormControl';
import { Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Edit as EditIcon } from '@mui/icons-material'; // Assuming you're using MUI
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import EventBus from '../../common/EventBus';
import adminService from '../../services/admin.service';
import '../../styles/ProviderConfig.css';
import SchedulingInfoModal from '../Modals/AddSchedulingModal/SchedulingInfoModal';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const AdminPracticeInfo = (props) => {
  const [inHIPAA, setInHIPAA] = React.useState('');
  const [hrsPerWeek, setHrsPerWeek] = React.useState('');
  const [schedule, setSchedule] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pw, setPw] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date('2025-12-31'));
  const [endDate, setEndDate] = React.useState(new Date('2025-12-31'));
  const [adminStatus, setAdminStatus] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newSchedule) => {
    setSchedule(newSchedule);
    handleCloseModal();
  };

  useEffect(() => {
    setInHIPAA(Boolean(props.selectedUserDetail?.hipaa) ?? '');
    setSchedule(props.selectedUserDetail?.schedule ?? '');
    setHrsPerWeek(props.selectedUserDetail?.hrs_p_week ?? '');
    setPw(props.selectedUserDetail?.pw);
    setStartDate(props.selectedUserDetail?.date_start);
    setEndDate(props.selectedUserDetail?.date_end);
    setAdminStatus(Boolean(props.selectedUserDetail?.is_active) ?? '');
  }, [props.selectedUserDetail]);

  useEffect(() => {
    adminService.getAdmins().then(
      async (response) => {
        // eslint-disable-next-line no-unused-vars
        let admins = response?.data?.users ?? [];
        // eslint-disable-next-line no-unused-vars
        admins = admins
          .filter((admin) => {
            return admin.roleId === 3;
          })
          .sort((admin1, admin2) => {
            const name1 = admin1?.username?.split(' ')[1] ?? '';
            const name2 = admin2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, []);

  const handleOnSubmit = async (event) => {
    props.showLoading();

    event.preventDefault();
    try {
      if (props?.selectedUser?.id) {
        if (props.selectedUserDetail?.admin_email && hrsPerWeek && schedule) {
          const formattedSchedule = schedule.map((sch) => {
            const safeStartTime = new Date(sch.startTime);
            const safeEndTime = new Date(sch.endTime);

            return {
              days: sch.days,
              startTime: safeStartTime.toISOString(), // Safely format startTime
              endTime: safeEndTime.toISOString(), // Safely format endTime
              setting: sch.setting,
              collapsed: sch.collapsed,
            };
          });

          const response = await adminService.updateAdminDetails(
            props.selectedUser.id,
            {
              admin_email: props.selectedUserDetail?.admin_email,
              hipaa: inHIPAA,
              hrs_p_week: hrsPerWeek,
              schedule: formattedSchedule, // Use the formatted schedule
              is_active: adminStatus,
              date_start: startDate,
              date_end: endDate,
              pw: pw,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Admin details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          } else {
            enqueueSnackbar('Updated Admin details.', {
              variant: 'success',
              timeout: 3000,
            });
          }
        } else {
          enqueueSnackbar('Please select required fields.', {
            variant: 'warning',
            timeout: 3000,
          });
        }
      } else {
        enqueueSnackbar('Please select Admin first.', {
          variant: 'warning',
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
      enqueueSnackbar('Failed to update details due to an error.', {
        variant: 'error',
        timeout: 3000,
      });
    } finally {
      props.hideLoading(); // hide the loading
    }
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid container spacing={3} className="GridItemTextField">
          <Grid item xs={3}>
            <FormControl
              className="fullWidthTextField borderedDropdown"
              sx={{ width: '100%' }}
            >
              <InputLabel id="demo-simple-select-label">
                HIPAA Workforce
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inHIPAA}
                onChange={(event) => {
                  setInHIPAA(event.target.value);
                }}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="hrsPerWeek"
                label="Hours Per Week"
                value={hrsPerWeek}
                onChange={(e) => {
                  setHrsPerWeek(e.target.value);
                }}
              />
            </Grid>
          }

          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid gray',
                borderRadius: '4px',
                padding: '16px',
                position: 'relative',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  position: 'absolute',
                  top: '-12px',
                  left: '16px',
                  backgroundColor: 'white',
                  padding: '0 8px',
                }}
              >
                Schedule
              </Typography>
              {schedule.length === 0 ? (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ marginBottom: '16px' }}
                >
                  No Saved Schedules
                </Typography>
              ) : (
                Array.isArray(schedule) &&
                schedule.map((sch, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid gray',
                      padding: '10px',
                      marginBottom: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography variant="body2">
                      <strong>Days:</strong> {sch.days.join(', ')}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Time:</strong>{' '}
                      {new Date(sch.startTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}{' '}
                      -{' '}
                      {new Date(sch.endTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Setting:</strong> {sch.setting}
                    </Typography>
                  </Box>
                ))
              )}
              <Box sx={{ textAlign: 'center' }}>
                {schedule.length === 0 ? (
                  <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    sx={{
                      backgroundColor: 'blue',
                      color: 'white',
                      marginTop: '16px',
                    }}
                  >
                    Create Schedule
                  </Button>
                ) : (
                  <IconButton
                    onClick={handleOpenModal}
                    sx={{ marginTop: '16px' }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <SchedulingInfoModal
              open={isModalOpen}
              onClose={handleCloseModal}
              onSave={handleSave}
              schedule={Array.isArray(schedule) ? schedule : []}
            />
          </Grid>

          <Grid item xs={3}>
            <button
              xs={3}
              className="btn btn-primary btn-block buttonWithMargin"
              type="button"
              onClick={handleOnSubmit}
            >
              Save
            </button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(AdminPracticeInfo);
