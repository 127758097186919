import React, { useState, useEffect, memo } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import UserService from '../../services/user.service';
import { useSelector } from 'react-redux';
import adminService from '../../services/admin.service';
import carecoordinatorService from '../../services/carecoordinator.service';
import providerService from '../../services/provider.service';

const DirectoryGrid = () => {
  const [directoryEntries, setDirectoryEntries] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [filter, setFilter] = useState('All'); // Filter state
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchDirectoryData = async () => {
      try {
        const status = showActive ? 'active' : 'inactive';
        const isActive = showActive; // This will be true if active, false if inactive

        // Fetch providers, admins, and users in parallel
        const [
          providersResponse,
          adminsResponse,
          careCoordinatorsResponse,
          usersResponse,
        ] = await Promise.all([
          providerService.getProviders(status),
          adminService.getAdmins(status),
          carecoordinatorService.getCareCoordinators(status),
          UserService.getAllUsers(),
        ]);

        const users = usersResponse.data.users;

        // Enrich providers with images and type
        const providersWithImages = providersResponse.data.providers
          .filter((provider) => provider.provider_name != null)
          .map((provider) => {
            const user = users.find((user) => user.id === provider.id);
            return {
              ...provider,
              name: provider.provider_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
              type: 'Provider', // Adding type for clarity in rendering
              isActive: isActive,
            };
          });

        // Enrich admins with images and type
        const adminsWithImages = adminsResponse.data.map((admin) => {
          const user = users.find((user) => user.id === admin.id);
          return {
            ...admin,
            name: admin.admin_name || 'No Name',
            imageUrl:
              user?.imageUrl ||
              'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg',
            type: 'Admin', // Adding type for clarity in rendering
            isActive: isActive,
          };
        });

        const careCoordinatorsWithImages = careCoordinatorsResponse.data.map(
          (careCoordinator) => {
            const user = users.find((user) => user.id === careCoordinator.id);
            return {
              ...careCoordinator,
              name: careCoordinator.care_coordinator_name || 'No Name',
              imageUrl:
                user?.imageUrl ||
                'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg', // Default image or use one specific for care coordinators
              type: 'Care Coordinator', // This type is used for filtering
              isActive: isActive, // This might need adjustment depending on how isActive is determined
            };
          }
        );

        // Combine and sort providers, admins, and care coordinators
        const combinedEntries = [
          ...providersWithImages,
          ...adminsWithImages,
          ...careCoordinatorsWithImages,
        ].sort((a, b) => {
          // Extract the primary name or fallback to an empty string
          const nameA = (a.name || '').toLowerCase();
          const nameB = (b.name || '').toLowerCase();
          return nameA.localeCompare(nameB);
        });

        setDirectoryEntries(combinedEntries);
      } catch (error) {
        console.error('Failed to fetch directory data:', error);
      }
    };

    fetchDirectoryData();
  }, [showActive]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredEntries = directoryEntries.filter((entry) => {
    if (filter === 'All') return true;
    return entry.type === filter;
  });

  return (
    <div>
      {' '}
      <FormControl
        variant="outlined"
        style={{ marginBottom: '1rem', minWidth: 200 }}
      >
        <InputLabel id="filter-label">Filter</InputLabel>
        <Select
          labelId="filter-label"
          id="filter"
          value={filter}
          onChange={handleFilterChange}
          label="Filter"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Provider">Providers</MenuItem>
          <MenuItem value="Admin">Admins</MenuItem>
          <MenuItem value="Care Coordinator">Care Coordinators</MenuItem>
        </Select>
      </FormControl>
      {currentUser?.roles.includes('ROLE_DOCTOR') ? (
        <Typography variant="h6">Active</Typography>
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={showActive}
              onChange={() => setShowActive(!showActive)}
              name="activeInactiveSwitch"
            />
          }
          label={showActive ? 'Active' : 'Inactive'}
        />
      )}
      <Grid container spacing={2}>
        {filteredEntries.map((entry, index) => (
          <Grid item xs={12} sm={2} md={2} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="100"
                image={entry.imageUrl}
                onError={(e) => {
                  e.target.src =
                    'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                }}
                alt="User Photo"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  component="div"
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    fontSize:
                      entry.name.length > 22
                        ? '0.8rem'
                        : entry.name.length > 20
                        ? '1.0rem'
                        : '1.1rem',
                  }}
                >
                  {entry.name}
                </Typography>

                {entry.isActive && (
                  <Typography variant="body2" color="text.secondary">
                    Ext: {entry.ext}
                  </Typography>
                )}

                <Typography variant="body2" color="text.secondary">
                  Mobile Phone: {entry.phone || entry.mobile_number}
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  Home Address: {entry.address1} {entry.address2}, {entry.city},{' '}
                  {entry.state} {entry.zip}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default memo(DirectoryGrid);
