import React, { useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import LoadingDialog from '../Dialogs/LoadingDialog.js';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import UserService from '../../services/user.service';
import {
  getAnniversaryEventsAndDelete,
  getBirthdayEventsAndDelete,
  getLicenseEventsAndDelete,
} from '../../common/utility';
import { setLight, clearLight } from '../../actions/light.js';
import driveService from '../../services/drive.service';

const DeleteUserPopup = ({ userId, name, value, showLoading, hideLoading }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  // The below two functions for setting the light and clearing the light (Means light loader)
  const showLight = useCallback(() => {
    dispatch(setLight());
  }, [dispatch]);

  const hideLight = useCallback(() => {
    dispatch(clearLight());
  }, [dispatch]);

  const handleDelete = async () => {
    try {
      // enabling the loader to show
      showLight();
      setIsDialogOpen(true);
      showLoading();

      const states = value?.licensed_in_other_states?.split(',');

      // Now here I am moving the drive Folder to expired (userId) (First step required)
      await driveService.moveProviderFolder(userId);

      // The below is deleteing user from database
      const response = await UserService.deleteUser({
        selectedUserId: userId,
      });
      // deleting the event from the calendar
      await getBirthdayEventsAndDelete(name);
      // deleting license Events from the calendar
      // As I am not passing state so i am deleting liability
      await getLicenseEventsAndDelete(name);

      // Now here I am deleting license in maryland state
      await getLicenseEventsAndDelete(name, 'Maryland');

      // Now here I am deleting the PsyPact license
      await getLicenseEventsAndDelete(name, 'PsyPact');

      // Now here I am deleting the Anniversary events
      await getAnniversaryEventsAndDelete(name);

      // Now here I am deleting license in states
      states?.map(async (state) => {
        await getLicenseEventsAndDelete(name, state);
      });

      // hiding the light loader closing the dialog
      hideLight();
      setIsDialogOpen(false);
      hideLoading();
      if (response) {
        enqueueSnackbar('User Deleted Successfully!', {
          variant: 'success',
          timeout: 3000,
        });
      }
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('error: ', error.message);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Delete User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title" style={{ color: 'red' }}>
          Warning!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete a user account. This action is irreversible
            and all associated data will be permanently lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ color: 'red' }}>
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* The below loading Dialog is a light Dialog to be displayed */}
      <LoadingDialog
        open={isDialogOpen}
        message={
          'User is getting deleted. It may take a while Please holdOn! and do not close the window'
        }
      />
    </>
  );
};

export default DeleteUserPopup;
