import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useState, useEffect, memo } from 'react';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Utility & Other Function Imports
import {
  additionalLanguagesSpokenOptions,
  degreesAndLicenseOptions,
  licensedInOtherStatesOptions,
  createOrUpdateBirthdayEvent,
} from '../../common/utility';

import EventBus from '../../common/EventBus';
import moment from 'moment';
import { parseISO } from 'date-fns';
import SupervisorService from '../../services/supervisor.service';
import UserService from '../../services/user.service';
import { useSnackbar } from 'notistack';
import '../../styles/ProviderConfig.css';
import adminService from '../../services/admin.service';
import providerService from '../../services/provider.service';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProviderPersonalInfoNewUser = (props) => {
  const [dob, setDob] = React.useState('');
  const [licensedInOtherStates, setLicensedInOtherStates] = React.useState('');
  const [additionalLanguagesSpoken, setAdditionalLanguagesSpoken] =
    React.useState([]);
  const [degreesAndLicense, setDegreesAndLicense] = React.useState([]);
  const [gender, setGender] = React.useState('');
  const [inTesting, setInTesting] = React.useState('');
  const [inTherapy, setInTherapy] = React.useState('');
  const [providerStatus, setProviderStatus] = React.useState(false);
  const [race, setRace] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState(null);

  const [supervisorName, setSupervisorName] = useState('');
  const [primarySuperVisorSelectedId, setPrimarySuperVisorSelectedId] =
    useState(); // This state is for selecting the Id of the primary supervisor
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [secondSupervisorName, setSecondSupervisorName] = useState('');
  const [selectedSecondOption, setSelectedSecondOption] = useState(null);
  const [supervisorEmail, setSupervisorEmail] = React.useState('');
  const [secondSupervisorEmail, setSecondSupervisorEmail] = React.useState('');
  const [supervisorType, setSupervisorType] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [secondSupervisorType, setSecondSupervisorType] = React.useState('');
  const [otherSupervisorTypeDescription, setOtherSupervisorTypeDescription] =
    React.useState('');
  const [
    otherSecondSupervisorTypeDescription,
    // eslint-disable-next-line no-unused-vars
    setOtherSecondSupervisorTypeDescription,
  ] = React.useState('');
  const [adminHandlingName, setAdminHandlingName] = React.useState('');
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [providerName, setProviderName] = React.useState('');
  const [staticDoxyLink, setStaticDoxyLink] = React.useState('');
  const [showFirstSupervisor, setShowFirstSupervisor] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [showSecondSupervisorCheckbox, setShowSecondSupervisorCheckbox] =
    useState(false);
  const [showSecondSupervisor, setShowSecondSupervisor] = useState(false);
  const [supervisorStatus, setSupervisorStatus] = useState('');
  const [secondSupervisorStatus, setSecondSupervisorStatus] = useState('');
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const isDoctorAdminSupervisor = (user) => {
    return (
      user &&
      user.roles &&
      user.roles.includes('ROLE_DOCTOR') &&
      user.roles.includes('ROLE_ADMIN') &&
      user.roles.includes('ROLE_SUPERVISOR')
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isValid = true; // Start assuming the form is valid

    isValid = isValid && dob !== 'Invalid date' && dob !== '';
    isValid = isValid && race !== '';
    isValid = isValid && gender !== '';
    isValid = isValid && inTherapy !== '';
    isValid = isValid && inTesting !== '';
    isValid = isValid && providerStatus !== '';
    isValid = isValid && degreesAndLicense.length > 0;
    isValid = isValid && adminHandlingName !== '';
    isValid = isValid && staticDoxyLink !== '';
    // Call onCompletionChange only if it's a function
    if (typeof props.onCompletionChange === 'function') {
      props.onCompletionChange(isValid);

      if (isValid) {
        // This below prop sets the selectedUser Detail for NewUser Dialog
        props.setSelectedUserDetailForProvider({
          dob: dob,
          race: race,
          gender: gender,
          inTherapy: inTherapy,
          inTesting: inTesting,
          providerStatus: providerStatus,
          degreesAndLicense: degreesAndLicense,
          additionalLanguagesSpoken: additionalLanguagesSpoken,
          adminHandlingName: adminHandlingName,
          staticDoxyLink: staticDoxyLink,
          licensedInOtherStates: licensedInOtherStates,
          primarySuperVisorSelectedId: primarySuperVisorSelectedId,
          relationshipType: supervisorType,
          primaryStatus: supervisorStatus,
          supervisorName: supervisorName,
          supervisorEmail: supervisorEmail,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dob,
    race,
    gender,
    inTherapy,
    inTesting,
    degreesAndLicense,
    providerStatus,
    providerName,
    licensedInOtherStates,
    staticDoxyLink,
    adminHandlingName,
    additionalLanguagesSpoken,
    shouldShow,
    selectedOption,
    primarySuperVisorSelectedId,
    supervisorType,
    supervisorStatus,
    supervisorName,
    supervisorEmail,
  ]);

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const date = moment(
      props.selectedUserDetail?.date_of_birth?.split('T')[0] ?? ''
    ).format('YYYY-MM-DD');
    if (date !== 'Invalid date') {
      setDob(date);
    }
    setLicensedInOtherStates(
      props.selectedUserDetail?.licensed_in_other_states
        ?.split(',')
        .map((obj) => {
          return obj.trim();
        }) ?? []
    );
    setAdditionalLanguagesSpoken(
      props?.selectedUserDetail?.additional_Languages_spoken ?? []
    );
    setDegreesAndLicense(props?.selectedUserDetail?.degrees_and_license ?? []);
    setGender(
      props.selectedUserDetail?.gender === 'm' ||
        props.selectedUserDetail?.gender === 'M'
        ? 'Male'
        : props.selectedUserDetail?.gender === 'f' ||
          props.selectedUserDetail?.gender === 'F'
        ? 'Female'
        : ''
    );
    setInTesting(props.selectedUserDetail?.in_testing ?? '');
    setProviderStatus(Boolean(props.selectedUserDetail?.is_active) ?? '');
    setInTherapy(props.selectedUserDetail?.in_therapy ?? '');
    setRace(props.selectedUserDetail?.race ?? '');
    setSupervisorName(props.selectedUserDetail?.supervisor_name);
    setSupervisorEmail(props.selectedUserDetail?.supervisor_email ?? '');
    setSecondSupervisorName(props.selectedUserDetail?.supervisor_2_name);
    setSecondSupervisorEmail(
      props.selectedUserDetail?.supervisor_2_email ?? ''
    );
    setAdminHandlingName(props.selectedUserDetail?.admin_handling_name ?? '');
    setStaticDoxyLink(props.selectedUserDetail?.static_doxy_link);
    setProviderName(
      props.selectedUserDetail?.provider_name ??
        props.selectedUserDetail?.username ??
        ''
    );

    setSupervisorType(props.selectedUserDetail?.relationshipType ?? ''); // assuming 'supervisor_type' is the key in your user detail
    setSupervisorStatus(props.selectedUserDetail?.primaryStatus); // assuming 'supervisor_status' is the key
    setSecondSupervisorStatus(props.selectedUserDetail?.secondaryStatus ?? ''); // assuming 'second_supervisor_status' is the key
    setSelectedOption({
      username: props.selectedUserDetail?.supervisor_name ?? '',
      email: props.selectedUserDetail?.supervisor_email ?? '',
      id: props.selectedUserDetail?.supervisorIdPrimary ?? '',
    });
    setPrimarySuperVisorSelectedId(
      props.selectedUserDetail?.supervisorIdPrimary
    );
  }, [props.selectedUserDetail]);

  useEffect(() => {
    UserService.getAllUsers().then(
      async (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 7; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        setSupervisorOptions(
          providers.map((provider) => {
            return {
              id: provider.id,
              username: provider.username,
              email: provider.email,
            };
          })
        );
      },
      (error) => {
        // * to use in the future if needed *
        // const _content =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
        // setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );

    adminService.getAdmins().then((response) => {
      /*
         The below has been updated to fetch admins from the Admin table
         */
      let adminUsers = response?.data ?? [];
      adminUsers = adminUsers.sort((adminUser1, adminUser2) => {
        const name1 = adminUser1?.admin_name?.split(' ')[1] ?? '';
        const name2 = adminUser2?.admin_name?.split(' ')[1] ?? '';
        return name1.localeCompare(name2);
      });
      setAdminUsers(adminUsers);
    });
  }, [
    dob,
    race,
    gender,
    inTherapy,
    inTesting,
    degreesAndLicense,
    providerStatus,
    providerName,
    licensedInOtherStates,
    staticDoxyLink,
    adminHandlingName,
    additionalLanguagesSpoken,
    shouldShow,
  ]);

  useEffect(() => {
    if (degreesAndLicense.includes('Non-Fully Licensed')) {
      setShowFirstSupervisor(true);
      setShowSecondSupervisorCheckbox(true);
    } else {
      setShowFirstSupervisor(false);
      setShowSecondSupervisorCheckbox(false);
      setShowSecondSupervisor(false);
    }
  }, [degreesAndLicense]);

  // In ProviderPersonalInfo component

  const handleLanguagesChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdditionalLanguagesSpoken(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleDegreesAndLicenseChange = (event) => {
    const {
      target: { value },
    } = event;
    setDegreesAndLicense(typeof value === 'string' ? value.split(',') : value);
  };

  const handlelicensedInOtherStatesChange = (event) => {
    const {
      target: { value },
    } = event;
    setLicensedInOtherStates(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  function handleChange(value, type) {
    if (type === 'first') {
      setSelectedOption(value);
      if (value) {
        setSupervisorName(value.username);
        setSupervisorEmail(value.email);
        setPrimarySuperVisorSelectedId(value.id);
      } else {
        setSupervisorName('');
        setSupervisorEmail('');
        setPrimarySuperVisorSelectedId(null);
      }
    } else if (type === 'second') {
      setSelectedSecondOption(value);
      if (value) {
        setSecondSupervisorName(value.username);
        setSecondSupervisorEmail(value.email);
      } else {
        setSecondSupervisorName('');
        setSecondSupervisorEmail('');
      }
    }
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    let validationPassed = true;

    let dobUpdated = dob !== 'Invalid date' ? dob : '';

    if (supervisorType === 'Primary' && secondSupervisorType === 'Primary') {
      enqueueSnackbar('You cannot have two Primary supervisors.', {
        variant: 'error',
        timeout: 3000,
      });
      validationPassed = false;
    }

    if (
      supervisorType === 'Secondary' &&
      secondSupervisorType === 'Secondary'
    ) {
      enqueueSnackbar('You cannot have two Secondary supervisors.', {
        variant: 'error',
        timeout: 3000,
      });
      validationPassed = false;
    }

    if (degreesAndLicense.includes('Non-Fully Licensed')) {
      if (!selectedOption || !supervisorType) {
        enqueueSnackbar('Please select the required supervisor fields.', {
          variant: 'error',
          timeout: 3000,
        });
        validationPassed = false;
      }

      if (supervisorType === 'Other' && !otherSupervisorTypeDescription) {
        enqueueSnackbar(
          'Please provide a description for "Other" supervisor type.',
          { variant: 'error', timeout: 3000 }
        );
        validationPassed = false;
      }

      if (selectedOption && !supervisorStatus) {
        enqueueSnackbar('Please select the supervisor status.', {
          variant: 'error',
          timeout: 3000,
        });
        validationPassed = false;
      }
    }

    if (showSecondSupervisor) {
      if (!selectedSecondOption || !secondSupervisorType) {
        enqueueSnackbar(
          'Please select the required second supervisor fields.',
          {
            variant: 'error',
            timeout: 3000,
          }
        );
        validationPassed = false;
      }

      if (
        secondSupervisorType === 'Other' &&
        !otherSecondSupervisorTypeDescription
      ) {
        enqueueSnackbar(
          'Please provide a description for "Other" second supervisor type.',
          { variant: 'error', timeout: 3000 }
        );
        validationPassed = false;
      }

      if (selectedSecondOption && !secondSupervisorStatus) {
        enqueueSnackbar('Please select the second supervisor status.', {
          variant: 'error',
          timeout: 3000,
        });
        validationPassed = false;
      }
    }

    // Only proceed if all validations passed
    if (!validationPassed) return;

    const providerData = {
      email: props.selectedUserDetail?.provider_email,
      supervisorEmail,
      supervisorName,
      adminHandlingName,
      additionalLanguagesSpoken,
      degreesAndLicense,
      dob: dobUpdated,
      gender,
      inTesting,
      inTherapy,
      licensedInOtherStates,
      race,
      staticDoxyLink,
      providerName: props.selectedUserDetail?.username,
      // providerName,
      secondSupervisorEmail,
      secondSupervisorName,
      is_active: providerStatus,
      // eslint-disable-next-line no-dupe-keys
      supervisorName: supervisorName,
      // eslint-disable-next-line no-dupe-keys
      supervisorEmail: supervisorEmail,
    };

    try {
      // Check if props.selectedUser?.id is available for update scenario
      if (props.mode === 'update' && props.selectedUser?.id) {
        const updateResponse = await providerService.updateProviderDetails(
          props.selectedUser.id,
          providerData
        );

        if (supervisorName && supervisorEmail && supervisorType) {
          await SupervisorService.createSupervisorRelationship({
            superviseeId: props.selectedUser.id,
            supervisorIdPrimary: primarySuperVisorSelectedId, // Fetch this ID from the backend
            relationshipType: supervisorType,
            primaryStatus: supervisorStatus, // Include the primary supervisor status
          });
        }
        if (
          updateResponse &&
          updateResponse.data &&
          updateResponse.data.message === 'success'
        ) {
          enqueueSnackbar('Provider details updated successfully.', {
            variant: 'success',
            timeout: 3000,
          });

          // Only attempt to create or update the birthday event if DOB is valid
          if (dobUpdated && dobUpdated !== 'Invalid date') {
            const formattedDOB = moment(dobUpdated).format('YYYY-MM-DD');
            createOrUpdateBirthdayEvent(providerData.providerName, formattedDOB)
              .then((msg) =>
                enqueueSnackbar(msg, { variant: 'success', timeout: 3000 })
              )
              .catch((err) => {
                console.error(
                  'Error creating or updating birthday event:',
                  err
                );
                enqueueSnackbar('Failed to create or update birthday event.', {
                  variant: 'error',
                  timeout: 3000,
                });
              });
          }

          props.updateProviderDetail();
        } else {
          enqueueSnackbar('Failed to update provider details.', {
            variant: 'error',
            timeout: 3000,
          });
        }
      } else {
        // In case of creation, you should handle this after user creation where you have new user ID
        console.log(
          'Provider creation is expected to be handled after user creation where the user ID is available.'
        );
      }
    } catch (error) {
      console.error('Error updating provider:', error);
      enqueueSnackbar('An error occurred while updating provider details.', {
        variant: 'error',
        timeout: 3000,
      });
    }
  };

  return (
    <React.Fragment>
      {props.isSupervisee && (
        <Typography variant="body1" className="providerNotice1">
          All sections of the provider configuration is read-only. Please reach
          out to Levi or Victoria if there is any information that you would
          like to update.
        </Typography>
      )}
      {props.isProvider &&
        !props.isSupervisee &&
        !isDoctorAdminSupervisor(props.user) && (
          <Typography variant="body1" className="providerNotice1">
            The "Personal Info" section of the provider profile is read-only.
            Please reach out to Levi or Victoria if there is any information
            that you would like to update.
          </Typography>
        )}
      <div className="mainContainer">
        <Grid container spacing={3} className="GridItemTextField">
          {!props.renderingThroughNewUser && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="provider_name"
                label="Provider Name"
                value={providerName}
                onChange={(e) => {
                  setProviderName(e.target.value);
                }}
                disabled={props.isProvider}
              />
            </Grid>
          )}

          {!props.renderingThroughNewUser && (
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                fullWidth
                variant="outlined"
                id="provider_email"
                label="Provider Email"
                value={props.selectedUserDetail?.provider_email ?? ''}
                disabled={props.isProvider}
              />
            </Grid>
          )}

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">Gender</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setGender(event.target.value);
                  }}
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">Race</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={race}
                  label="In Therapy"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setRace(event.target.value);
                  }}
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'African American'}>
                    African American
                  </MenuItem>
                  <MenuItem value={'Asian'}>Asian</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {dob !== undefined && (
            <Grid item xs={3}>
              <DatePicker
                className="fullWidthTextField borderedDropdown"
                autoOk
                autoComplete="off"
                name="date_of_birth"
                fullWidth
                variant="inline"
                inputVariant="outlined"
                label="Date of Birth"
                format="yyyy-MM-dd"
                value={dob !== '' ? parseISO(dob) : null} // I am here preventing for the default date to be set
                disabled={props.isProvider}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => {
                  const updatedDate = moment(date).format('yyyy-MM-DD');
                  setDob(updatedDate);
                }}
              />
            </Grid>
          )}

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">
                  Additional Languages Spoken
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={
                    Array.isArray(additionalLanguagesSpoken)
                      ? additionalLanguagesSpoken
                      : []
                  }
                  onChange={handleLanguagesChange}
                  input={<OutlinedInput label="Additional Languages Spoken" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                  disabled={props.isProvider}
                >
                  {additionalLanguagesSpokenOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={additionalLanguagesSpoken.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }

          {shouldShow && (
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  Provider Active Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={providerStatus}
                  onChange={(event) => {
                    setProviderStatus(event.target.value);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} className="GridItemTextField">
          {
            <Grid item xs={3}>
              <Autocomplete
                disablePortal
                id="admin_handling_name"
                label="Admin Handling"
                defaultValue={adminHandlingName}
                options={adminUsers}
                value={adminHandlingName}
                disabled={props.isProvider}
                onChange={(event, value) => {
                  setAdminHandlingName(value?.admin_name);
                }}
                renderInput={(params) => (
                  <TextField
                    className="fullWidthTextField borderedDropdown"
                    variant="outlined"
                    {...params}
                    name="adminHandling_"
                    label="Admin Handling"
                  />
                )}
                getOptionLabel={
                  (option) => option.admin_name || adminHandlingName
                  // The above getting adminName
                }
              />
            </Grid>
          }

          {
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="static_doxy_link"
                label="Static Doxy Link"
                value={staticDoxyLink}
                disabled={props.isProvider}
                onChange={(e) => {
                  setStaticDoxyLink(e.target.value);
                }}
              />
            </Grid>
          }

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  In Testing
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inTesting}
                  label="In Testing"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setInTesting(event.target.value);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  In Therapy
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inTherapy}
                  label="In Therapy"
                  disabled={props.isProvider}
                  onChange={(event) => {
                    setInTherapy(event.target.value);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">
                  Degrees And License
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={
                    Array.isArray(degreesAndLicense) ? degreesAndLicense : []
                  }
                  onChange={handleDegreesAndLicenseChange}
                  disabled={props.isProvider}
                  input={<OutlinedInput label="Degrees And License" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                >
                  {degreesAndLicenseOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={degreesAndLicense.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }

          {
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-multiple-name-label">
                  Licensed In Other States
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  disabled={props.isProvider}
                  value={
                    Array.isArray(licensedInOtherStates)
                      ? licensedInOtherStates
                      : []
                  }
                  onChange={handlelicensedInOtherStatesChange}
                  input={<OutlinedInput label="Licensed In Other States" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected?.join(', ')}
                >
                  {licensedInOtherStatesOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={licensedInOtherStates.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
        </Grid>

        {showFirstSupervisor && (
          <Grid container spacing={3} className="GridItemTextField">
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="supervisorName-label">Supervisor</InputLabel>
                <Select
                  labelId="supervisorName-label"
                  id="supervisorName"
                  variant="outlined"
                  disabled={props.isProvider}
                  value={selectedOption}
                  onChange={(event) =>
                    handleChange(event.target.value, 'first')
                  }
                  renderValue={(selected) => (
                    <TextField
                      fullWidth
                      variant="standard"
                      value={selected.username}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                >
                  {supervisorOptions.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="supervisor-type-label">Type</InputLabel>
                <Select
                  labelId="supervisor-type-label"
                  id="supervisor-type"
                  variant="outlined"
                  disabled={props.isProvider}
                  value={supervisorType}
                  onChange={(event) => setSupervisorType(event.target.value)}
                  // disabled={props.isProvider}
                >
                  <MenuItem value={'Primary'}>Primary</MenuItem>
                  <MenuItem value={'Secondary'}>Secondary</MenuItem>
                  <MenuItem value={'Coverage'}>Coverage</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {supervisorType === 'Other' && (
              <Grid item xs={3}>
                <TextField
                  className="fullWidthTextField"
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                  id="other-supervisor-type-description"
                  label="Other Type Description"
                  value={otherSupervisorTypeDescription}
                  onChange={(e) =>
                    setOtherSupervisorTypeDescription(e.target.value)
                  }
                  disabled={props.isProvider}
                />
              </Grid>
            )}

            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="supervisor-status-label">
                  Supervisor Status
                </InputLabel>
                <Select
                  labelId="supervisor-status-label"
                  id="supervisor-status"
                  value={supervisorStatus}
                  defaultValue={supervisorStatus}
                  onChange={(event) => setSupervisorStatus(event.target.value)}
                  displayEmpty
                  disabled={props.isProvider} // Disable if necessary depending on the role
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {
              <Grid item xs={3}>
                <TextField
                  className="fullWidthTextField borderedDropdown"
                  autoComplete="off"
                  fullWidth
                  variant="outlined"
                  id="supervisor_Email"
                  label="Supervisor Email"
                  readOnly
                  disabled
                  value={supervisorEmail}
                  onChange={(e) => {
                    setSupervisorEmail(e.target.value);
                  }}
                />
              </Grid>
            }
            {/*This below has been commented temporarily */}

            {/* {showSecondSupervisorCheckbox && (
              <Grid item xs={3}>
                <Checkbox
                  checked={showSecondSupervisor}
                  onChange={() =>
                    setShowSecondSupervisor(!showSecondSupervisor)
                  }
                  inputProps={{ 'aria-label': 'Add Second Supervisor' }}
                />
                Add Second Supervisor
              </Grid>
            )} */}
          </Grid>
        )}

        <Grid container spacing={3} className="GridItemTextField"></Grid>
        {!props.renderingThroughNewUser && (
          <Grid container spacing={3} className="GridItemTextField">
            <Grid item xs={3}>
              <button
                xs={3}
                className="btn btn-primary btn-block buttonWithMargin"
                type="button"
                onClick={handleOnSubmit}
                disabled={props.isProvider}
              >
                Save
              </button>
            </Grid>
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(ProviderPersonalInfoNewUser);
