import React from 'react';
import Box from '@mui/material/Box';
import { blue, purple, green, yellow, pink, red, brown, orange} from '@mui/material/colors';
import PanToolIcon from '@mui/icons-material/PanTool';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import Badge from '@mui/material/Badge';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const IconKeyBox = () => {

  const colorMap = {
    far: blue[500],       // Using Material-UI color shades
    near: pink[100],
    closer: orange[500],
    expiring: red[500]
};
  const renderLocationAvatar = (letter, color) => (
    <Box
       sx={{
                bgcolor: color,
                fontSize: '0.5rem',
                width: 15,
                height: 15,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '0.5em', // Add some space between the avatar and the text
            }}
    >
      {letter}
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid black',
        padding: '0.5em',
        height: 'auto', // Set height here
      }}
    >
      {/* Section 1: Locations */}
      <Box
        sx={{
          flex: '1 1 auto',
          borderRight: '1px solid gray',
          padding: '0.1em 1em 1em 1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            marginBottom: '0em',
            marginTop: '-0.5em',
            alignSelf: 'center',
          }}
        >
          Locations
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderLocationAvatar('Rem', yellow[700])} Remote
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderLocationAvatar('Bal', purple[500])} Baltimore
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderLocationAvatar('SS', green[500])} Silver Spring
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderLocationAvatar('Col', blue[500])} Columbia
        </Box>
      </Box>

      {/* Section 2 for Frequency */}
      <Box
        sx={{
          flex: '1 1 auto',
          borderRight: '1px solid gray',
          padding: '0.1em 1em 1em 1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            marginBottom: '0.2em',
            marginTop: '-0.5em',
            alignSelf: 'center',
          }}
        >
          Frequency
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}
        >
          <Box
            sx={{
              width: 15,
              height: 15,
              bgcolor: 'black',
              color: 'white',
              marginRight: '0.5em',
              fontSize: '0.75rem',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            W
          </Box>

          <span>Weekly</span>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}
        >
          <Box
            sx={{
              width: 15,
              height: 15,
              bgcolor: pink[100],
              color: 'white',
              marginRight: '0.5em',
              fontSize: '0.45rem',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            EOW
          </Box>
          <span>Every Other Week</span>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}
        >
          <Box
            sx={{
              width: 15,
              height: 15,
              bgcolor: brown[300],
              color: 'white',
              marginRight: '0.5em',
              fontSize: '0.75rem',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            FL
          </Box>
          <span>Floater</span>
        </Box>
      </Box>

      {/* Section: Type */}
      <Box
        sx={{
          flex: '1 1 auto',
          borderRight: '1px solid gray',
          padding: '0.1em 1em 1em 1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            marginBottom: '0em',
            marginTop: '-0.5em',
            alignSelf: 'center',
          }}
        >
        Type & Badges
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <EventAvailableIcon
            sx={{
              width: '22px',
              height: '22px',
              color: 'green',
              marginRight: '0.3em',
            }}
          />{' '}
          Open
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PanToolIcon
            sx={{
              width: '22px',
              height: '22px',
              color: 'red',
              marginRight: '0.5em',
              marginTop: '0.2em', 
            }}
          />{' '}
          Hold
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1em', }}>
          <Badge
            badgeContent={''}
            sx={{
              marginRight: '0.5em',
              '& .MuiBadge-badge': { backgroundColor: 'green' },
            }}
          >
            <EventAvailableIcon sx={{ color: 'green' ,width: '22px',
              height: '22px',}} />
          </Badge>
          <span>&nbsp;&nbsp;Total Openings</span>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5em', }}>
          <Badge
            badgeContent={''}
            sx={{
              marginRight: '0.5em',
              '& .MuiBadge-badge': { backgroundColor: 'orange' },
            }}
          >
            <EventAvailableIcon sx={{ color: 'green' }} />
          </Badge>
          <span> &nbsp; Openings 14+ Days </span>
        </Box>
      </Box>

    

      {/* Section: Slot Age */}
      <Box
        sx={{
          flex: '2 1 auto',
          borderRight: '1px solid gray',
          padding: '0.1em 1em 1em 1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            fontWeight: 'bold',
            marginBottom: '0em',
            marginTop: '-0.5em',
            alignSelf: 'center',
          }}
        >
          Slot Age Icons
        </Box>

        {/* Less than 14 days */}
        {/* Less than 14 days */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.2em' }}> {/* Reduced margin */}
          <Box
            sx={{
              width: '18px',
              height: '18px',
              border: '1px solid grey',
              backgroundColor: 'transparent',
              marginRight: '0.5em',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
         <span>&lt; 14 Days</span>
        </Box>

        {/* 14-20 days */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeTwoToneIcon
            sx={{
              width: '18px',
              height: '18px',
              color: 'goldenrod',
              marginRight: '0.5em',
            }}
          />
          <span>14-20 Days</span>
        </Box>

        {/* 21-27 days */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeTwoToneIcon
            sx={{
              width: '18px',
              height: '18px',
              color: 'darkorange',
              marginRight: '0.5em',
            }}
          />
          <span>21-27 Days</span>
        </Box>

        {/* 28+ days */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccessTimeTwoToneIcon
            sx={{
              width: '18px',
              height: '18px',
              color: 'red',
              marginRight: '0.5em',
            }}
          />
          <span>28+ Days</span>
        </Box>
        {/* New Boxes for <21 and >21 Days */}
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // This centers the content vertically in a column flex container
    alignItems: 'center', // This centers the content horizontally in the container
    width: '100%', // This ensures the box takes full width available in its parent
    height: '10px', // Optional: Adjust the height based on your design needs
    marginTop: '1em', // Adds some space below the container
    marginBottom: '.25em',
  }}
>
  <span><b>Slot Age Boxes</b></span>
</Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
      <Box sx={{ width: 20, height: 20, bgcolor: 'white', border: '1px solid black', marginRight: '0.5em' }} />
      <span>Less than 21 Days</span>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
      <Box sx={{ width: 20, height: 20, bgcolor: 'beige', border: '1px solid black', marginRight: '0.5em' }} />
            <span>More than 21 Days</span>
          </Box>
      </Box>
      </Box>




  {/* New Section: Hold Expiration Status */}
  <Box
        sx={{
          flex: '1 1 auto',
          padding: '0.1em 1em 1em 1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ fontWeight: 'bold', marginBottom: '0em', marginTop: '-0.5em', alignSelf: 'center' }}>
          Hold Expiration Status
        </Box>
        {['far', 'near', 'closer', 'expiring'].map((stage) => (
          <Box key={stage} sx={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em' }}>
            <PanToolIcon sx={{ color: 'red', marginRight: '0.5em', fontSize: '18px' }} />
            <WatchLaterIcon sx={{ color: colorMap[stage], fontSize: '18px' }} />
            <span>{`${stage.charAt(0).toUpperCase() + stage.slice(1)} (${stage === 'far' ? '7+ days' : stage === 'near' ? '4-7 days' : stage === 'closer' ? '2-3 days' : '1 day or less'})`}</span>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default IconKeyBox;